<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="bug"
                        class="mr-2"
                    ></font-awesome-icon>
                    Traceback
                </h2>
            </b-card>
            <b-card
                class="mt-2"
                title="Filter"
                title-tag="h5"
            >
                <b-input placeholder="Search information..." v-model="filter"></b-input>
            </b-card>
        </b-skeleton-wrapper>
        <div
            class="scroll-x"
        >
            <b-table
                class="mt-2 w-100"
                :busy="loading"
                striped
                bordered
                hover
                :items="items"
                :fields="fields"
                selectable
                select-mode="single"
                :filter="filter"
                empty-text="There are no results that match your criteria."
                empty-filtered-text="There are no results that match your criteria."
            >
                <template #cell(user.email)="{ item }">
                    <a :href="'mailto:' + item.user.email">
                        <font-awesome-icon
                            icon="envelope"
                            class="mr-2"
                        ></font-awesome-icon>
                        {{ item.user.email }}
                    </a>
                </template>
                <template #cell(actions)="row">
                    <div class="text-center">
                        <b-button
                            variant="link"
                            size="sm"
                            @click="row.toggleDetails"
                        >
                            <font-awesome-icon :icon="(row.detailsShowing) ? 'eye-slash' : 'eye'"></font-awesome-icon>
                        </b-button>
                    </div>
                </template>
                <template #row-details="{ toggleDetails, item }">
                    <b-button-close @click="toggleDetails()">&times;</b-button-close>  
                    <h6>Url: <code class="ml-2">{{ item.url }}</code></h6>
                    <h6>Query String: <code class="ml-2">{{ item.query_string }}</code></h6>
                    <h6>Method: <code class="ml-2">{{ item.method }}</code></h6>
                    <h6>Mimetype: <code class="ml-2">{{ item.mimetype }}</code></h6>
                    <div>
                        <h6>Headers:</h6>
                        <pre>{{ item.headers }}</pre>
                    </div>
                    <div>
                        <h6>Body:</h6>
                        <pre>{{ item.body }}</pre>
                    </div>
                    <div>
                        <h6>Error Message:</h6>
                        <pre>{{ item.error_message }}</pre>
                    </div>
                </template>
                <template #empty-text>
                    There are no records to fetch.
                </template>
            </b-table>
        </div>
    </b-container>
</template>
<script>
export default {
    props: ["class_id"],
    data() {
        return {
            loading: false,
            selectedClass: {},
            show: [ "active" ],
            filter: "",
            fields: [
                {
                    key         : "user.person.first_name",
                    label       : "First Name",
                    sortable    : true
                },
                {
                    key         : "user.person.last_name",
                    label       : "Last Name",
                    sortable    : true
                },
                {
                    key         : "user.email",
                    label       : "Email",
                    sortable    : true
                },
                {
                    key         : "error_date",
                    label       : "Error at",
                    sortable    : true,
                    formatter: (value) => {
                        if (value == null) return "No date"
                        return this.$moment(value).format("LLLL");
                    }
                },
                /*{
                    key: "birthdate",
                    label: "Age",
                    formatter: (value) => {
                        return this.$moment().diff(value, "years");
                    },
                    sortable: true
                },*/
                {
                    key: "actions"
                },
            ],
            items: []
        };
    },
    mounted() {
        this.loading = true;
        this.$api.get("/api/admin/tracebacks").then((response) => {
            const data         = response.data;
            this.items         = data.tracebacks;
            this.loading       = false;
        });
    },
    methods: {
        onStudentSelected(student) {
            alert("SELECTED student", student);
            student._details = !student._details;
        },
        showActive(row) {
            console.log("helloww");
            return row.is_active == true;
        },
        getAllergyInfo(student) {
            try {
                const personal_info = JSON.parse(student.personal_info);
                if (personal_info == null) {
                    return "none";
                } else {
                    return personal_info.allergy_info;
                }
            } catch (e) {
                return "Error";
            }
        }
    },
    computed: {
        users() {
            return this.items.filter((item) => {
                if      (this.show.includes("active") && this.show.includes("inactive")) return true;
                else if (this.show.includes("active"))      return item.is_active;
                else if (this.show.includes("inactive"))    return !item.is_active;
                return false;
            });
        },
        mailtoAll() {
            return "mailto:" + this.items.filter((user) => user.is_active == 1).map((user) => user.email).join(",");
        }
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>